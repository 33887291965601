<template>
  <div>
    <b-card>
      <b-table
        id="tablarda"
        :items="items"
        :fields="fields"
        hover
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No records found"
        @row-clicked="toggleDetails">
        <template #cell(view)="data">
          <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails" />
        </template>

        <template #cell(show)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails" />
        </template>

        <template #row-details="row">
          <b-card>
            <b-container>
              <b-row>
                <b-col cols="0">
                  <strong>Patient:</strong>
                </b-col>
                <b-col>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.patient_name }}
                    {{ row.item.patient_lastname }}
                  </span>
                </b-col>
                <b-col cols="0">
                  <strong>Nurse:</strong>
                </b-col>
                <b-col>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.nurse_name }}
                  </span>
                </b-col>
                <b-col> <strong>Visit Date : </strong>{{ row.item.visit_date }} </b-col>
              </b-row>
              <b-row>
                <b-col cols="0">
                  <strong>Oma:</strong>
                </b-col>
                <b-col>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.oma_name }}
                  </span>
                </b-col>
                <b-col cols="0">
                  <strong>Frontdesk:</strong>
                </b-col>
                <b-col>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.frontdesk_name }}
                  </span>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col>
                  <b-card title="Visit Reason: ">
                    <span>
                      {{ row.item.reason_visit }}
                    </span>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
            <br />
            <b-button
              class="mr-2"
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails">
              Hide Details
            </b-button>
            <b-button size="sm" variant="outline-secondary" @click="seeDetails(row.item)">
              See details
            </b-button>
          </b-card>
        </template>
        <template #cell(is_membership)="row">
          <span>
            <i
              :class="
                row.item.is_membership
                  ? 'fa fa-star text-warning'
                  : 'fa fa-star text-muted'
              "></i>
          </span>
        </template>
        <template #cell(type_membership)="row">
          <span>
            {{ row.item.type_membership }}
          </span>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
      <div id="otroLugarEnElDOM"></div>
      <detailsModal
        :visit="visit"
        :openModal.sync="modalDetailBool"
        :patient="patient_name" />
    </b-card>
  </div>
</template>

<script>
import { BContainer, BCard, BRow, BCol, BButton, BFormCheckbox } from 'bootstrap-vue'
import PaginationTable from '@/components/PaginationTable'
import * as XLSX from 'xlsx/xlsx.mjs'
import axios from '@/core/services/api/admin/adminData'
import detailsModal from './detailsModal'

export default {
  components: {
    detailsModal,
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormCheckbox,
    PaginationTable,
  },
  props: {
    datarda: {
      type: Object,
      default: () => {},
      required: false,
    },
    propDataExcel: {
      type: Object,
      required: false,
    },
  },
  watch: {
    datarda() {
      if (this.datarda) {
        const { data, current_page, from, total, to, per_page } =
          this.datarda.ROWS_PAGINATE
        this.items = data
        this.dataMeta = { from, to, of: total }
        this.totalUsers = total
        this.perPage = per_page
        this.currentPage.page = current_page
        this.excel()
      }
    },
    propDataExcel: {
      handler(newVal, oldVal) {
        // Aquí puedes manejar los cambios en la variable propDataExcel
        if (newVal) this.excel()
      },
      deep: true, // Esto observará los cambios profundos en la variable si es un objeto o matriz
      immediate: true, // Esto hará que el watcher se ejecute inmediatamente después de que el componente se monte
    },
  },
  data() {
    return {
      items: [],
      fields: [
        'show',
        'total',
        'ajustment',
        'cash',
        'cc',
        'consult_price',
        'facility',
        'type_visit',
        'visit_date',
        'pay_status',
        'visit_status',
        'is_membership',
        'type_membership',
      ],
      dataMeta: { from: 1, to: 0, of: 0 },
      totalUsers: null,
      perPage: null,
      currentPage: {
        page: 1,
      },
      visit: {},
      modalDetailBool: false,
      patient_name: '',
    }
  },
  methods: {
    async seeDetails({ id, patient_name }) {
      const { office_visit } = await axios.getDetailsOfficeVisit(id)
      this.patient_name = patient_name
      this.visit = office_visit
      this.modalDetailBool = true
    },
    pageChanged(page) {
      this.$emit('paginated', page)
    },
    excel() {
      new Promise((resolve, reject) => {
        let dataExcel
        if (
          this.propDataExcel &&
          this.propDataExcel.DATA_EXCEL &&
          this.propDataExcel.DATA_EXCEL.length > 0
        ) {
          dataExcel = {}
          dataExcel['data'] = this.propDataExcel.DATA_EXCEL
          resolve((this.items = dataExcel.data))
        } else {
          resolve((this.items = []))
        }
      })
        .then(() => {
          const originalDiv = document.getElementById('tablarda')
          const table_elt = originalDiv.cloneNode(true) // El parámetro "true" indica que también se deben clonar los descendientes
          const rows = table_elt.rows
          const ws_data = [
            [
              'Total',
              'Ajustment',
              'Cash',
              'Cc',
              'Consult Price',
              'Facility',
              'Type Visit',
              'Visit Date',
              'Pay Status',
              'Visit Status',
              'Is Membership',
            ],
          ]

          // Recorrer todas las filas y eliminar el primer <td> de cada una
          for (let i = 0; i < rows.length; i++) {
            const firstCell = rows[i].getElementsByTagName('td')[0]

            if (firstCell) {
              rows[i].removeChild(firstCell)
            }

            // Obtener la celda de fecha (Reemplaza columnIndex con el índice de la columna de fechas)
            const dateCell = rows[i].getElementsByTagName('td')[7]

            if (dateCell) {
              const dateValue = dateCell.textContent.trim()
              const formattedDate = formatDateWithTime(dateValue) // Función para formatear la fecha con hora, minutos y segundos
              dateCell.textContent = formattedDate
            }

            const row = []
            const cells = rows[i].getElementsByTagName('td')
            for (let j = 0; j < cells.length; j++) {
              row.push(cells[j].textContent)
            }
            ws_data.push(row)
          }
          // Agregar la nueva hoja de cálculo al libro
          const ws = XLSX.utils.aoa_to_sheet(ws_data)

          this.$emit('sendDataTable', ws)
        })
        .then(() => (this.items = this.datarda.ROWS_PAGINATE.data))

      function formatDateWithTime(dateString) {
        const date = new Date(dateString)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const seconds = date.getSeconds().toString().padStart(2, '0')
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
